// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path="/terms" page={TermsPage} name="terms" />
      <Route path="/admin" page={AdminPage} name="admin" />
      <Private unauthenticated="home">
        <Route
          path="/admin/clients/new"
          page={NewClientPage}
          name="newClient"
        />
        <Route
          path="/admin/clients/{id:Int}/edit"
          page={EditClientPage}
          name="editClient"
        />
        <Route path="/admin/clients/{id:Int}" page={ClientPage} name="client" />
        <Route path="/admin/clients" page={ClientsPage} name="clients" />
      </Private>
      <Route path="/" page={HomePage} name="home" />
      <Route path="/register" page={RegisterPage} name="register" />
      <Route path="/plan" page={PlanPage} name="plan" />
      <Route path="/photo" page={PhotoPage} name="photo" />
      <Route path="/terms" page={TermsPage} name="terms" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
